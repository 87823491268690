import React from 'react';

import { Container, MainContent } from './styles';

interface MainProps {
  children: any;
}

const MainLayout: React.FC<MainProps> = ({ children }) => {
  return (
    <Container id="container">
      <MainContent id="main-content">{children}</MainContent>
    </Container>
  );
};

export default MainLayout;
