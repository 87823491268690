import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

export const Container = styled.div`
  background: #e7e6e7e8;
  page-break-inside: avoid !important;
  break-inside: avoid-column;
`;

export const CardText = styled(Card.Text)`
  font-size: 1.3vw;
`;
