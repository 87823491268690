import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { Container } from 'react-bootstrap';
import answerBubble from '../../assets/answer_babble.png';
import { CardText } from './styles';

interface props {
  answer: any;
}

const UserCard: React.FC<props> = ({ answer }) => {
  return (
    <Container>
      <Card style={{ width: '100%', background: 'rgb(241 240 247 / 89%)' }}>
        <Card.Body style={{ padding: '1vw' }}>
          <Card.Title>
            {answer.user_group}
            <Image
              src={answerBubble}
              style={{ width: '17%', float: 'right' }}
              roundedCircle
            ></Image>
          </Card.Title>
          <CardText style={{ fontSize: '1.3vw' }}>{answer.answer}</CardText>
        </Card.Body>
      </Card>
    </Container>
  );
};
export default UserCard;
