import React, { useEffect, useState } from 'react';
import { Breadcrumb, CategoryTitle, QuestionTitle } from './styles';

interface props {
  showSidebar: any;
  node: any;
}

interface BreadCrumb {
  question: string;
  category1: string;
  category2: string;
}

const BreadcrumbTree: React.FC<props> = ({ showSidebar, node }) => {
  const [breadCrumb, setBreadCrumb] = useState<BreadCrumb>({
    question: '',
    category1: '',
    category2: '',
  });

  useEffect(() => {
    if (node !== undefined) {
      if (node.depth === 0) {
        setBreadCrumb({
          question: '',
          category1: '',
          category2: '',
        });
      }

      if (node.depth === 1) {
        if (!node.clicked) {
          setBreadCrumb({
            question: '',
            category1: '',
            category2: '',
          });
        } else {
          setBreadCrumb({
            question: `${node.name}    |  n =  ${node.countPeopleAnswered}`,
            category1: '',
            category2: '',
          });
        }
      }

      if (node.depth === 2) {
        setBreadCrumb({
          question: `${node.parent.name}    |  n =  ${node.parent.countPeopleAnswered}`,
          category2: node.name,
          category1: '',
        });
      }

      if (node.depth === 3) {
        setBreadCrumb({
          question: `${node.parent.parent.name}   |  n =  ${node.parent.parent.countPeopleAnswered}`,
          category2: node.parent.name,
          category1: node.name,
        });
      }
    }
  }, [node, showSidebar]);

  return (
    <>
      <Breadcrumb id="breadcrumb">
        <QuestionTitle>{breadCrumb.question}</QuestionTitle>
        <CategoryTitle>
          {breadCrumb.category2}{' '}
          {breadCrumb.category1 === '' ? '' : `- ${breadCrumb.category1}`}
        </CategoryTitle>
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbTree;
