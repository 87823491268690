import { Button, Select } from 'antd';
import styled from 'styled-components';

export const FiltersContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersButtonGroup = styled.div``;

export const DropdownTitle = styled.h6`
  font-size: 13px;
`;

export const Dropdown = styled(Select)`
  padding-bottom: 13px;
`;

export const ButtonFilters = styled(Button)``;
