import styled from 'styled-components';
import Offcanvas from 'react-bootstrap/Offcanvas';

export const H2Sidebar = styled.h2`
  color: #e88500;
  font-size: 1.4vw;
  margin-top: 5%;
`;

export const OffcanvasBody = styled(Offcanvas.Body)`
  background-color: #484848;
  padding: 1vw;
`;

export const ContainerItem = styled.div`
  page-break-inside: avoid !important;
  break-inside: avoid-page !important;
`;

export const ContainerPrint = styled.div`
  @media print {
    div {
      background: transparent;
      color: #000;
      text-shadow: none;
      filter: none;
      -ms-filter: none;
    }
  }
`;

export const PrintWrapper = styled.div`
  @media print {
    width: 100%;
    background-color: white !important;
    color: black !important;
    padding: 20px;
    text-align: center;

    * {
      background-color: initial !important; /* Mantém o background original dos cards */
      color: initial !important; /* Mantém a cor original do texto dos cards */
    }
  }
`;

export const PrintContainer = styled.div`
  @media print {
    display: flex;
    flex-direction: column;
    align-items: center;
    page-break-inside: avoid !important;

    .card {
      background-color: #f1f0f7e3 !important; /* Mantém o background original dos cards */
      color: initial !important; /* Mantém a cor original do texto dos cards */
      page-break-inside: avoid !important; /* Evita a quebra de página no meio dos cards */
      break-inside: avoid-page !important; /* Evita a quebra de página no meio dos cards */
    }
  }
`;

export const PrintHeader = styled.div`
  @media print {
    width: 100%;
    background-color: #ff780c !important;
    color: #ffffff !important;
    padding: 20px;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    page-break-before: always !important; /* Repete o header no topo de cada página */
  }
`;
