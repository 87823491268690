const getURLParams = (): any => {
  const decodedUrl = decodeURI(window.location.search);
  // test
  const queryParameters = new URLSearchParams(decodedUrl);

  const filters: any = {
    customerSector: queryParameters.getAll('customerSector'),
    customerRegion: queryParameters.getAll('customerRegion'),
    customerNumberEmployees: queryParameters.getAll('customerNemployees'),
    customerRevenues: queryParameters.getAll('customerRevenues'),
    customerName: queryParameters.getAll('customerName'),

    projectName: queryParameters.getAll('projectName'),
    projectType: queryParameters.getAll('projectType'),

    surveyYear: queryParameters.getAll('surveyYear'),
    surveyName: queryParameters.getAll('surveyName'),

    questions: queryParameters.getAll('questions'),
    accessName: queryParameters.getAll('accessName'),
    usergroup: queryParameters.getAll('usergroup'),
    segment: queryParameters.getAll('segment'),
    nTopic: queryParameters.getAll('nTopic'),
    showAnswers: queryParameters.getAll('showAnswers'),
  };

  const urlParams: any = {};

  Object.keys(filters).forEach((param: any) => {
    if (filters[param].length > 0) {
      urlParams[param] = filters[param];
    }
  });

  return urlParams;
};

export default getURLParams;
