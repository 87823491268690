// import { BrowserRouter } from 'react-router-dom';
// import RouteOptions from './routes/Route';

// const App: React.FC = () => {
//   return (
//     <BrowserRouter>
//       <RouteOptions />
//     </BrowserRouter>
//   );
// };

// export default App;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// import GlobalStyle from './styles/global';

// import AppProvider from './hooks';

import Routes from './routes';

const App: React.FC = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
