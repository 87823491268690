import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import BubbleChartPage from '../pages/BubbleChartPage';
import InitialPage from '../pages/InitialPage';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={InitialPage} />
    <Route
      path="/bubblechart/internal"
      exact
      component={BubbleChartPage}
      isPrivate
    />
    <Route
      path="/bubblechart/:id/:code"
      exact
      component={BubbleChartPage}
      isPrivate
    />
  </Switch>
);

export default Routes;
