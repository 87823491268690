import styled from 'styled-components';

export const Breadcrumb = styled.div`
  height: 100%;
  margin-top: 1%;
`;

export const QuestionTitle = styled.h4`
  color: white;
  padding: 3px 20px;
  margin-bottom: 0px;
  font-size: 1.8vw;
`;

export const CategoryTitle = styled.p`
  color: white;
  padding: 3px 20px;
  font-size: 1.8vw;
`;
