import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';

// import { useAuth } from '../hooks/auth';

import MainLayout from '../components/mainLayout';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isSurvey?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  if (rest?.location?.search.includes('langCode')) {
    const params = new URLSearchParams(window.location.search);
    const langCode = params.get('langCode');
    document.documentElement.setAttribute('lang', langCode ?? 'de');
  } else {
    document.documentElement.setAttribute('lang', 'de');
  }
  // const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return (
          <MainLayout>
            <Component />
          </MainLayout>
        );
      }}
    />
  );
};

export default Route;
