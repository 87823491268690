/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'antd';
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import UserCard from '../card/userCard';
import {
  H2Sidebar,
  ContainerItem,
  PrintContainer,
  PrintHeader,
  PrintWrapper,
  OffcanvasBody,
} from './styles';

interface props {
  show: boolean;
  handleClose: any;
  node: any;
}

const SideBar: React.FC<props> = ({ show, handleClose, node }) => {
  const pdfRef = useRef(null);
  const myArray: any = [];

  // if node is a leaf
  if ('answers' in node) {
    for (let i = 0; i < node.answers.length; i += 1) {
      const answer = node.answers[i];
      const id = `${i.toString()}space`;
      const idCard = `${i.toString()}card`;
      myArray.push(
        <ContainerItem key={i}>
          <H2Sidebar key={id} id={id}>
            {answer.originTopic === undefined ? '' : answer.originTopic}
          </H2Sidebar>
          <UserCard key={idCard} answer={answer}></UserCard>
        </ContainerItem>,
      );
    }
  }

  return (
    <>
      <Offcanvas
        style={{ width: '30%' }}
        show={show}
        key="end"
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header style={{ backgroundColor: '#ff780c' }} closeButton>
          <Offcanvas.Title style={{ color: '#FFFFFF', fontSize: '1.5vw' }}>
            {node.name}
          </Offcanvas.Title>
          <ReactToPrint
            content={() => pdfRef.current}
            trigger={() => (
              <Button
                style={{
                  width: '40%',
                  height: '100%',
                  fontSize: '1.3vw',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Export PDF
              </Button>
            )}
          />
        </Offcanvas.Header>
        <OffcanvasBody>{myArray}</OffcanvasBody>
      </Offcanvas>
      <div style={{ display: 'none' }}>
        <PrintWrapper ref={pdfRef}>
          <PrintHeader>
            <Offcanvas.Header
              style={{ backgroundColor: '#ff780c' }}
              closeButton
            >
              <Offcanvas.Title style={{ color: '#FFFFFF' }}>
                {node.name}
              </Offcanvas.Title>
            </Offcanvas.Header>
          </PrintHeader>
          <PrintContainer>{myArray}</PrintContainer>
        </PrintWrapper>
      </div>
    </>
  );
};

export default SideBar;
