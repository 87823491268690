/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const copyData = (data: any): any => {
  // export function copyData(data: any) {
  const newData: any = {};

  if (data) {
    newData.name = data.name;
    newData.splitedNamePart1 = '';
    newData.splitedNamePart2 = '';
    newData.countAnswers = data.countAnswers;

    const newQuestions: any = [];
    const questions = data._children ? data._children : data.children;
    questions.forEach((question: any) => {
      const newQuestion: any = {
        name: question.name,
        countPeopleAnswered: question.countPeopleAnswered,
        splitedNamePart1: question.splitedNamePart1,
        splitedNamePart2: question.splitedNamePart2,
        countAnswers: question.countAnswers,
        _children: [],
      };
      const newCategories2: any = [];

      const categories2 = question._children
        ? question._children
        : question.children;
      categories2.forEach((category2: any) => {
        const newCategory2: any = {
          name: category2.name,
          splitedNamePart1: category2.splitedNamePart1,
          splitedNamePart2: category2.splitedNamePart2,
          countAnswers: category2.countAnswers,
          _children: [],
        };
        const newCategories1: any = [];

        const categories1 = category2._children
          ? category2._children
          : category2.children;
        categories1.forEach((category1: any) => {
          const newCategory1: any = {
            name: category1.name,
            splitedNamePart1: category1.splitedNamePart1,
            splitedNamePart2: category1.splitedNamePart2,
            countAnswers: category1.countAnswers,
            answers: category1.answers,
          };
          newCategories1.push(newCategory1);
        });
        newCategory2._children = newCategories1;
        newCategories2.push(newCategory2);
      });

      newQuestion._children = newCategories2;
      newQuestions.push(newQuestion);
    });
    newData.children = newQuestions;
  }
  return newData;
};

export default copyData;
