import { Button, FloatButton, InputNumber, Popover, SelectProps } from 'antd';
import React, { useCallback, useState } from 'react';
import { ImFilter } from 'react-icons/im';
import { FaObjectGroup } from 'react-icons/fa';
import { IoMdArrowDropdownCircle } from 'react-icons/io';
import { BiReset } from 'react-icons/bi';
import {
  Dropdown,
  DropdownTitle,
  FiltersButtonGroup,
  FiltersContent,
} from './styles';

interface props {
  urlParams: any;

  dropdownOptions: any;
  setNtopics: any;
  setResetFilters: any;
  getFilteredData: any;
}

const FloatButtonGroup: React.FC<props> = ({
  urlParams,

  dropdownOptions,
  setNtopics,
  setResetFilters,
  getFilteredData,
}) => {
  const [open, setOpen] = useState(false);
  const [dropdownChoises, setDropdownChoises] = useState({});
  const [selectedNtopicsValue, setSelectedNtopicsValue] = useState(5);

  const [segments] = useState<SelectProps['options']>(
    dropdownOptions?.options?.segments?.map((segment: any) => {
      const obj = {
        label: segment,
        value: segment,
      };
      return obj;
    }) ?? [],
  );

  const [links] = useState<SelectProps['options']>(
    dropdownOptions?.options?.links?.map((link: any) => {
      const obj = {
        label: link,
        value: link,
      };
      return obj;
    }) ?? [],
  );

  const [usergroups] = useState<SelectProps['options']>(
    dropdownOptions?.options?.usergroups?.map((usergroup: any) => {
      const obj = {
        label: usergroup,
        value: usergroup,
      };
      return obj;
    }) ?? [],
  );

  const handleOpenFloatButton = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleApplyNtopics = (): void => {
    setNtopics(selectedNtopicsValue);
  };

  const handleChangeNtopics = (value: any): void => {
    setSelectedNtopicsValue(value);
  };

  const topicsContent = (
    <>
      <div>
        <InputNumber
          onChange={handleChangeNtopics}
          style={{ position: 'relative', left: '5px' }}
          size="small"
          min={1}
          max={100}
          defaultValue={5}
        />
      </div>
      <Button style={{ position: 'relative', top: '6px', left: '50px' }}>
        clear
      </Button>
      <Button
        style={{ position: 'relative', top: '6px', left: '50px' }}
        onClick={handleApplyNtopics}
      >
        apply
      </Button>
    </>
  );

  const handleChangeSegment = (choise: any): void => {
    const obj: any = {
      ...dropdownChoises,
      segment: choise,
    };
    if (choise.length === 0) {
      delete obj.segment;
    }
    setDropdownChoises(obj);
  };
  const handleChangeLink = (choise: any): void => {
    const obj: any = {
      ...dropdownChoises,
      accessName: choise,
    };
    if (choise.length === 0) {
      delete obj.access_name;
    }
    setDropdownChoises(obj);
  };
  const handleChangeUsergroup = (choise: any): void => {
    const obj: any = {
      ...dropdownChoises,
      usergroup: choise,
    };
    if (choise.length === 0) {
      delete obj.usergroup;
    }
    setDropdownChoises(obj);
  };

  const handleClickApplyFilters = (): void => {
    if (Object.keys(dropdownChoises).length === 0) {
      const obj: any = { ...urlParams };
      delete obj.segment;
      delete obj.access_name;
      delete obj.usergroup;

      getFilteredData(obj);
    } else {
      const obj: any = { ...urlParams, ...dropdownChoises };
      getFilteredData(obj);
    }
  };

  const handleResetFilters = (): void => {
    const nTopicsLocal = urlParams.nTopic;
    setNtopics(nTopicsLocal);
    setResetFilters(true);
  };

  const filtersContent = (
    <>
      <FiltersContent>
        <DropdownTitle>Segment</DropdownTitle>
        <Dropdown
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          onChange={handleChangeSegment}
          options={segments}
        />
        <DropdownTitle>Link</DropdownTitle>
        <Dropdown
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          onChange={handleChangeLink}
          options={links}
        />
        <DropdownTitle>Usergroup</DropdownTitle>
        <Dropdown
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          onChange={handleChangeUsergroup}
          options={usergroups}
        />
        <Button type="primary" onClick={handleClickApplyFilters}>
          apply
        </Button>
      </FiltersContent>
    </>
  );

  return (
    <>
      <FiltersButtonGroup id="filters-button-group">
        <FloatButton.Group
          open={open}
          trigger="click"
          type="primary"
          style={{ right: 24 }}
          icon={<ImFilter />}
          onClick={handleOpenFloatButton}
        >
          <FloatButton onClick={handleResetFilters} icon={<BiReset />} />
          <Popover
            placement="leftBottom"
            title={'N-Topics Filter'}
            content={topicsContent}
            trigger="click"
          >
            <FloatButton icon={<FaObjectGroup />} />
          </Popover>
          <Popover
            placement="leftBottom"
            title={'Filters'}
            content={filtersContent}
            trigger="click"
          >
            <FloatButton icon={<IoMdArrowDropdownCircle />} />
          </Popover>
        </FloatButton.Group>
      </FiltersButtonGroup>
    </>
  );
};

export default FloatButtonGroup;
